<template>
  <Page isHeader :isBack="status == 1">
    <div class="content lk-p-b-60" ref="test">
      <!-- 暂未绑定储值卡 -->
      <div v-if="status === 0" class="no-card">
        <van-image
          width="120"
          height="120"
          :src="require('@/assets/images/ic_no_bind.svg')"
        />
        <!-- 暂未绑定储值卡 -->
        <p class="no-card-tips font-1 lk-m-t-16 lk-text-bold">暂未领取储值卡</p>

        <div class="lk-flex lk-m-t-50">
          <van-button
            v-if="orgIsElectronicCardPay"
            class="font-1 lk-m-r-6 lk-m-l-6"
            color="#F8B500"
            block
            round
            @click="handleClickBind(1)"
          >
            前往领取
          </van-button>
          <van-button
            class="font-1 lk-m-r-6 lk-m-l-6"
            color="#F8B500"
            block
            round
            @click="handleClickBind(0)"
          >
            绑定储值卡
          </van-button>
        </div>
        <!--  -->
        <div
          class="bind-other-card lk-m-b-20 lk-m-t-30 content"
          @click="showLoginOut = true"
        >
          <!-- 切换手机号 -->
          退出登录
        </div>
      </div>
      <!-- 当前卡绑定其他手机号 -->
      <div v-if="status === 2" class="has-bind no-card">
        <van-image
          width="120"
          height="120"
          :src="require('@/assets/images/ic_has_bind.png')"
        />
        <p class="no-card-tips font-1 lk-m-t-16">当前卡片已绑定其他手机号</p>
        <van-button
          class="lk-m-t-60 font-1"
          color="#F8B500"
          block
          round
          @click="handleClickChangePhone"
        >
          更换手机号
        </van-button>
      </div>
      <!-- 已经绑定储值卡 -->
      <div v-if="status === 1" class="card-box-wrap">
        <!-- 绑定多张当前手机号的储值卡 -->
        <p class="lk-m-t-12">选择卡片</p>
        <!-- 卡信息 -->
        <div
          v-for="(card, idx) in cardList"
          :key="idx"
          :style="{
            backgroundImage:
              'url(' +
              $baseImg +
              (card.coverUrl || 'photo/h5C/card/card_default.png') +
              ')',
          }"
          class="card-box content lk-relative lk-m-t-12"
          @click="handleToDetail(card)"
        >
          <div class="card-info lk-flex lk-m-t-12">
            <van-image
              round
              width="36"
              height="36"
              :src="$baseImg + card.cardLogo || 'photo/h5C/card/card_logo.png'"
            />
            <span class="info lk-font-16 van-ellipsis">
              {{ card.cardName }}
            </span>
          </div>
          <p class="card-num lk-absolute lk-font-14">
            卡号：
            <span>{{ NumberCut(card.cardNo || '') }}</span>
          </p>
        </div>
        <!-- 绑定其他卡片 -->
        <div
          v-if="cardList && cardList.length < 3"
          class="bind-other-card lk-m-t-20 content"
        >
          <span @click="handleClickBind(0)">绑定储值卡</span>
          <template v-if="orgIsElectronicCardPay">
            <span class="lk-p-l-8 lk-p-r-8">/</span>
            <span @click="handleClickBind(1)">领取电子卡</span>
          </template>
        </div>
      </div>
    </div>
    <Popup :visible="isPopShow" title="储值卡使用协议">
      <div slot="content">
        <p class="agreement-content">
          1、储值卡内的金额只能在指定售货机购买，不能抵其他消费，可咨询发卡方了解详情。
          <br />
          2、应妥善保管储值卡，如因将卡转交其他人、遗失等情况，造成卡片被复制，产生的风险由用户自行承担。
          <br />
          3、储值卡遗失后，可自行挂失，挂失成功后，可立即办理补卡手续，同时需交补卡费，原卡号内的金额将转入新卡，同时旧卡作废。
          <br />
          4、若您以非法、欺诈或利用本平台漏洞的方式使用储值卡，本平台有权随时终止您使用储值卡在本平台购买。
        </p>
      </div>
      <div slot="footer" class="content">
        <van-button
          class="lk-m-t-16 font-1"
          color="#F8B500"
          block
          round
          @click="handleClickApply"
        >
          同意协议
        </van-button>
      </div>
    </Popup>
    <Dialog
      :visible.sync="showLoginOut"
      :content="loginOutMessage"
      @confirm="handleLoginOut"
    />
  </Page>
</template>

<script>
// [
//     {
//         "cardNo": 1000125629,
//         "cardName": "一张储值卡",
//         "serialNo": "xXfk7w",
//         "cardLogo": "CARD1622429898733",
//         "coverUrl": "CARD1623057988889"
//     },
//     {
//         "cardNo": 1000125974,
//         "cardName": "一张储值卡",
//         "serialNo": "QGUpTp",
//         "cardLogo": "CARD1622429898733",
//         "coverUrl": "CARD1623057988889"
//     },
//     {
//         "cardNo": 1000126031,
//         "cardName": "一张储值卡",
//         "serialNo": "j5QugS",
//         "cardLogo": "CARD1622429898733",
//         "coverUrl": "CARD1623057988889"
//     },
//     {
//         "cardNo": 1000126346,
//         "cardName": "一张储值卡",
//         "serialNo": "OvRP2O",
//         "cardLogo": "CARD1622429898733",
//         "coverUrl": "CARD1623057988889"
//     }17320507965
// 15986548563
// ]
import Popup from '@/components/Popups';
import { mapState, mapMutations, mapActions } from 'vuex';
import { NumberCut, phoneFmt } from '@/utils/format';
import { checkElectronCardPayApi } from '@/api/card.js';
export default {
  name: 'CardList',
  components: {
    Popup,
  },
  data() {
    return {
      NumberCut,
      status: 0,
      memberCardPay: 0, // 商户是否开启会员
      showLoginOut: false,
      loginOutMessage: '是否确认退出当前登录？',
      orgIsElectronicCardPay: false,
    };
  },
  activated() {
    console.log(this.$route.name);
    this.status = Number(this.$route.query.status || 0);
    this.$nextTick(async () => {
      this.status = Number(this.$route.query.status || 0);
      if (this.status === 1) {
        // 切换卡，多张卡
        const data = await this.getCardListAction();
        console.log(data);
        if (!data.content.length) {
          this.status = 0;
        }
        if (data.content.length === 1) {
          this.$router.replace({
            name: 'User',
            query: {
              cardNo: data.content[0].cardNo,
              serialNo: data.content[0].serialNo,
            },
          });
        }

        // console.log(this.status, typeof this.status);
      }
      if (this.status === -1) {
        console.log('===========回到redirect重新校验', 1);
        this.resetToLogin();
        console.log('===========回到redirect重新校验', 3);
        return false;
      }
      if (!localStorage.getItem('routeFlag')) {
        localStorage.setItem('routeFlag', 'true');
        window.location.reload();
      }
      this.checkElectronCardPay();
    });
  },
  computed: {
    ...mapState('app', ['isPopShow']),
    ...mapState('entery', ['memberInfo']),
    ...mapState('card', ['cardList', 'cardInfo']),
  },
  methods: {
    ...mapMutations('app', ['SET_POP_SHOW']),
    ...mapActions('card', ['getCardListAction']),
    ...mapActions('app', ['resetToLogin']),

    async checkElectronCardPay() {
      const { content } = await checkElectronCardPayApi({
        orgId: localStorage.getItem('ORG_ID'),
      });
      this.orgIsElectronicCardPay = content;
      console.log('orgIsElectronicCardPay==>', this.orgIsElectronicCardPay);
    },
    async handleClickBind(n) {
      this.memberCardPay = n;
      this.SET_POP_SHOW(true);
    },

    async handleClickApply() {
      await this.SET_POP_SHOW(false);
      // if (this.status == 0) {
      if (this.memberCardPay == 1) {
        this.$router.push({
          name: 'Choose',
        });
      } else {
        this.$router.push({
          name: 'CardBind',
        });
      }
    },
    handleClickChangePhone() {
      this.loginOutMessage = `请用绑定手机${phoneFmt(
        this.cardInfo.phone
      )}进行登录`;
      this.showLoginOut = true;
      // this.$router.push({
      //   name: 'ChangePhone',
      //   query: {
      //     phone: this.memberInfo.phone,
      //     cardNo: this.cardInfo.cardNo,
      //     serialNo: this.cardInfo.serialNo,
      //   },
      // });
    },
    handleToDetail(card) {
      console.log(card);
      this.$router.push({
        name: 'User',
        query: {
          cardNo: card.cardNo,
          serialNo: card.serialNo,
        },
      });
    },
    handleLoginOut() {
      // 延时跳转登录页，是确保当前页面弹窗已关闭
      // （因为页面缓存，弹窗还未来得及关闭就会被缓存，再次进入其他有该弹窗组件的页面，
      //   就会直接显示前一个未关闭的弹窗）
      this.showLoginOut = false;
      const timer2 = setTimeout(() => {
        this.resetToLogin();
        clearTimeout(timer2);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-card {
  margin-top: 120px;
  text-align: center;
  .no-card-tips {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }
}
.card-box {
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 210px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  .card-info {
    margin: 12px 0;
    padding: 12px 0;
    .info {
      flex: 1;
      display: inline-block;
      margin-left: 12px;
      font-weight: 500;
      color: #fff;
    }
  }
  .card-status {
    top: 10px;
    right: 0;
    background-color: #f56c6c;
    color: #fff;
    font-size: 12px;
    border-radius: 10px 0 0 10px;
    height: 20px;
    line-height: 20px;
    padding: 0 10px;
    text-align: center;
  }
  .card-num {
    left: 12px;
    bottom: 24px;
    color: #fff;
  }
}
.agreement-content {
  line-height: 24px;
}
.bind-other-card {
  width: 100%;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f8b500;
  line-height: 40px;
  text-align: center;
  box-sizing: border-box;
}
</style>
